<script setup>
import { useBlog } from "~/composables/useBlog";

const seoTitle = "Miraway Group - Trang chủ";
const seoDescription =
  "Miraway Group luôn đặt uy tín lên hàng đầu, cam kết mang đến cho khách hàng những sản phẩm và dịch vụ chất lượng cao với giá cả hợp lý.";

useHead({
  title: seoTitle,
  meta: [
    { name: "description", content: seoDescription },
    { name: "og:title", content: seoTitle },
    { name: "og:description", content: seoDescription },
    { name: "keywords", content: "Miraway Group" },
  ],
});

const { isVisibleBlog } = useBlog(2000);

const chooseOption = ref(0);

const onChoodeOption = (index) => {
  chooseOption.value = index;
};

const aboutUs = [
  {
    id: 0,
    title: "Sứ mệnh",
    des: [
      {
        title: "Sáng tạo công nghệ - Kiến tạo đất nước",
        content:
          "Miraway Group cam kết mang đến cho khách hàng những giải pháp công nghệ tiên tiến nhất giúp nâng cao chất lượng cuộc sống và kiến tạo một tương lai tốt đẹp hơn.",
      },
    ],
    image: "/images/home/sang-tao-cong-nghe-kien-tao-dat-nuoc.png",
  },
  {
    id: 1,
    title: "Tầm nhìn",
    des: [
      {
        title: "Vươn tầm phát triển",
        content:
          "Trở thành nhà cung cấp giải pháp Công nghệ hàng đầu Việt Nam và khu vực. Đến năm 2030 trở thành công ty hàng đầu trong lĩnh vực chuyển đổi số tại Đông Nam Á giúp các tổ chức, doanh nghiệp ở mọi quy mô phát triển và thành công trong kỷ nguyên số.",
      },
    ],
    image: "/images/home/tam-nhin-1.png",
  },
  {
    id: 2,
    title: "Giá trị cốt lõi",
    des: [
      {
        title: "Khát vọng",
        content:
          "Miraway luôn hướng tới những mục tiêu cao cả hơn, giúp Miraway vượt qua khó khăn mà còn tạo ra một môi trường làm việc đầy năng lượng và nhiệt huyết.",
      },
      {
        title: "Sáng tạo",
        content:
          " Miraway không ngừng sáng tạo và đổi mới, luôn tìm kiếm những giải pháp công nghệ mới nhất để giúp Miraway luôn dẫn đầu thị trường và đáp ứng nhu cầu đa dạng của khách hàng.",
      },
      {
        title: "Tận tâm",
        content:
          "Sự tận tâm thể hiện ở việc luôn đặt lợi ích của khách hàng lên hàng đầu, hoàn thành công việc với chất lượng cao nhất và hỗ trợ đồng đội hết mình.",
      },
      {
        title: "Chuyên nghiệp",
        content: "Chuyên nghiệp không chỉ là kỹ năng mà còn là thái độ làm việc nghiêm túc và có trách nhiệm.",
      },
      {
        title: "Bứt phá",
        content: "Tinh thần bứt phá giúp Miraway luôn sẵn sàng đối mặt với thử thách và vượt qua giới hạn.",
      },
    ],
    image: "/images/home/gia-tri-cot-loi.png",
  },
];

const whyChooseUs = [
  {
    id: 1,
    title: "",
    des: "",
    icon: null,
    image: "/images/home/why-choose-us-bg-0.png",
    hover_content: "MẠNG LƯỚI ĐỐI TÁC PHỦ RỘNG KHẮP CẢ NƯỚC",
  },
  {
    id: 2,
    title: "ĐỘI NGŨ NHÂN SỰ GIỎI",
    des: "Miraway Group tự hào với đội ngũ nhân sự dày dặn kinh nghiệm, nhiệt huyết và hoài bão, đặt nền móng cho sự phát triển mạnh mẽ trên thị trường công nghệ thông tin tại Việt Nam.",
    icon: "/images/home/why-choose-us.svg",
    image: null,
    hover_content: "",
  },
  {
    id: 3,
    title: "",
    des: "",
    icon: null,
    image: "/images/home/why-choose-us-bg-3.png",
    hover_content: "KIẾN TẠO PHÁT TRIỂN VƯƠN TẦM KHU VỰC",
  },
  {
    id: 4,
    title: "CHẤT LƯỢNG VÀ DỊCH VỤ",
    des: "Miraway tự tin đáp ứng  mọi nhu cầu của khách hàng, giúp tổ chức,  doanh  nghiệp nâng cao hiệu quả  hoạt động, thúc đẩy  tăng trưởng doanh thu.",
    icon: "/images/home/why-choose-us2.svg",
    image: null,
    hover_content: "",
  },
  {
    id: 5,
    title: "",
    des: "",
    icon: null,
    image: "/images/home/why-choose-us-bg-4.png",
    hover_content: "GIẢI PHÁP TÙY CHỈNH VÀ TIÊN PHONG CÔNG NGHỆ",
  },
  {
    id: 6,
    title: "ĐỐI TÁC CHIẾN LƯỢC ",
    des: "Miraway Group mở rộng hành trình vươn ra thị trường khu vực, mang những giải pháp công nghệ tiên tiến mũi nhọn tới các tổ chức, doanh nghiệp tại Đông Nam Á, Châu Á và Châu Mỹ.",
    icon: "/images/home/why-choose-us3.svg",
    image: null,
    hover_content: "",
  },
];

const solutions = [
  {
    id: 1,
    title: "Kiosk tự phục vụ",
    icon: "/images/home/self-service.svg",
    url: "https://miraway.vn/giai-phap/self-service-kiosk",
  },
  {
    id: 2,
    title: "VMS AI Camera",
    icon: "/images/home/camera.svg",
    url: "https://miraway.vn/giai-phap/vms-camera",
  },
  {
    id: 3,
    title: "Đặt lịch & xếp hàng thông minh ",
    icon: "/images/home/calendar.svg",
    url: "https://miraway.vn/giai-phap/he-thong-xep-hang-tu-dong",
  },
  {
    id: 4,
    title: "Digital Signage",
    icon: "/images/home/touch-screen.svg",
    url: "https://miraway.vn/giai-phap/digital-signage",
  },
  {
    id: 5,
    title: "Quản lý tài sản",
    icon: "/images/home/data-handling.svg",
    url: "https://miraway.vn/giai-phap/quan-ly-tai-san",
  },
  {
    id: 6,
    title: "Giải pháp chuyển đổi xanh",
    icon: "/images/home/green-innovation.svg",
    url: "https://mirawaygroup.com/blog/giai-phap-chuyen-doi-xanh/",
  },
  {
    id: 7,
    title: "Trải nghiệm khách hàng đa kênh",
    icon: "/images/home/vending-machine.svg",
    url: "https://miraway.vn/giai-phap/cx",
  },
  {
    id: 8,
    title: "Quản lý kênh phân phối QRX",
    icon: "/images/home/cloud-service.svg",
    url: "https://qrx.com.vn/",
  },
];

const valueDelivered = [
  {
    id: 1,
    content: "Khách hàng sẵn sàng trả nhiều tiền hơn để nhận được trải nghiệm khách hàng tốt hơn (theo CX Index 2021)",
    image: "/images/home/99percent.png",
  },
  {
    id: 2,
    content: "Các doanh nghiệp cho rằng cạnh tranh chủ yếu dựa trên trải nghiệm khách hàng (theo Gartner, 2021)",
    image: "/images/home/89percent.png",
  },
  {
    id: 3,
    content:
      "Đầu tư vào trải nghiệm khách hàng giúp doanh nghiệp tăng gấp đôi lợi nhuận trong vòng 3 năm (theo Temkin Group, 2018)",
    image: "/images/home/200percent.png",
  },
  {
    id: 4,
    content: "Kiểm soát chất lượng dịch vụ theo thời gian thực (theo triển khai thực tế của Miraway)",
    image: "/images/home/99percent-but-orange.png",
  },
  {
    id: 5,
    content: "Số lượng doanh nghiệp đầu tư vào trải nghiệm khách hàng đa kênh tăng từ 20% lên hơn 80% (theo PWC, 2020)",
    image: "/images/home/80percent.png",
  },
];
</script>

<template>
  <div class="home-page px-16 max-xl:px-4">
    <section class="rounded-[48px] relative">
      <div class="max-sm:hidden -mx-20 max-lg:-mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/banner/banner.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>
      <div class="sm:hidden -mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/banner/banner-mobie.png"
          :imgAttrs="{ class: 'w-full h-[85vh]', alt: 'home-banner-mobile' }"
          class="w-full"
        />
      </div>

      <div
        class="2xl:container mx-auto absolute max-2xl:bottom-[15px] top-1/2 max-md:top-[48px] left-[16px] right-[16px] md:-translate-y-1/2 max-md:left-0 max-md:right-0"
      >
        <p class="subtitle-1 text-secondary mt-2">CUNG CẤP GIẢI PHÁP</p>

        <h1 class="heading-1 text-white text-left mt-4 mb-7 max-sm:my-2">
          Chuyển đổi số & Chuyển đổi xanh
          <br class="max-md:hidden" />
          hàng đầu Việt Nam
        </h1>

        <p class="home-1-content max-w-[527px] max-sm:text-[16px] max-sm:mt-[18px] max-md:hidden">
          Miraway Group là đơn vị dẫn đầu, hướng tới mục tiêu trở thành đơn vị tiên phong trong việc ứng dụng công nghệ
          số, chúng tôi luôn khát vọng, sáng tạo và phát triển không ngừng để đóng góp vào sự phát triển bền vững và
          hiện đại hóa nền kinh tế Việt Nam.
        </p>

        <p class="home-1-content !font-normal max-sm:text-[16px] max-sm:mt-[18px] hidden max-md:block">
          Miraway Group luôn đặt uy tín lên hàng đầu, cam kết mang đến cho khách hàng những sản phẩm và dịch vụ chất
          lượng cao với giá cả hợp lý.
        </p>

        <div class="relative mt-7 flex gap-2 max-md:flex-col">
          <nuxt-link to="/ve-chung-toi">
            <button
              class="bg-primary border-primary max-md:bg-white max-md:!text-[#003D8A] w-full text-[16px] font-[700] flex items-center justify-center"
            >
              Tìm hiểu thêm
            </button>
          </nuxt-link>
          <nuxt-link to="/lien-he">
            <button
              class="border-2 border-white text-white max-md:w-full max-sm:text-[16px] max-sm:font-[700] max-sm:mt-[12px] mb-[48px] flex items-center justify-center"
            >
              Liên hệ
            </button>
          </nuxt-link>
        </div>
      </div>
    </section>

    <section id="about-us" class="mt-[80px] 2xl:container m-auto max-md:mt-2">
      <div class="text-center">
        <h2 class="text-primary home-4-title max-sm:text-[28px] max-sm:mt-[32px]">Về chúng tôi</h2>
        <div class="max-w-[584px] home-about-us mt-4 mx-auto text-center text-[#23262F] leading-[150%]">
          Trải qua hơn 17 năm hình thành và phát triển, Miraway Group không ngừng nỗ lực, xây dựng nền móng và khẳng
          định vị thế, đồng hành cùng hàng nghìn tổ chức, doanh nghiệp trong hành trình chinh phục thành công.
        </div>
      </div>

      <div class="mt-10 max-sm:hidden">
        <div class="grid grid-cols-3 gap-3 max-lg:px-0 border-b border-[#E6E8EC]">
          <div
            class="text-center pb-3"
            v-for="option in aboutUs"
            :key="option.id"
            :class="chooseOption === option.id && 'border-b-2 border-primary'"
          >
            <button
              class="w-full text-[#777E90] border-none max-lg:px-0 font-semibold heading-4"
              :class="chooseOption === option.id && 'text-primary'"
              @click="onChoodeOption(option.id)"
            >
              {{ option.title }}
            </button>
          </div>
        </div>
        <div class="mt-10 max-lg:px-0 flex gap-8 items-center max-lg:flex-col" v-if="chooseOption === 0">
          <div class="flex-1">
            <div v-for="content in aboutUs[chooseOption].des">
              <div class="text-primary home-2-tab-title text-[#] mt-6">{{ content.title }}</div>
              <div class="text-medium home-2-tab-content">{{ content.content }}</div>
            </div>
          </div>
          <div class="flex-1">
            <nuxt-picture
              loading="lazy"
              :src="aboutUs[chooseOption].image"
              :img-attrs="{ alt: 'sang-tao-cong-nghe-kien-tao-dat-nuoc', class: 'w-full' }"
            />
          </div>
        </div>
        <div class="mt-10 max-lg:px-0 flex gap-8 items-center max-lg:flex-col" v-if="chooseOption === 1">
          <div class="flex-1">
            <div v-for="content in aboutUs[chooseOption].des">
              <div class="text-primary home-2-tab-title text-[#] mt-6">{{ content.title }}</div>
              <div class="text-medium home-2-tab-content">{{ content.content }}</div>
            </div>
          </div>
          <div class="flex-1">
            <nuxt-picture
              loading="lazy"
              :src="aboutUs[chooseOption].image"
              :img-attrs="{ alt: 'sang-tao-cong-nghe-kien-tao-dat-nuoc', class: 'w-full' }"
            />
          </div>
        </div>
        <div class="mt-10 max-lg:px-0 flex gap-8 items-center max-lg:flex-col" v-if="chooseOption === 2">
          <div class="flex-1">
            <div v-for="content in aboutUs[chooseOption].des">
              <div class="text-primary home-2-tab-title text-[#] mt-6">{{ content.title }}</div>
              <div class="text-medium home-2-tab-content">{{ content.content }}</div>
            </div>
          </div>
          <div class="flex-1">
            <nuxt-picture
              loading="lazy"
              :src="aboutUs[chooseOption].image"
              :img-attrs="{ alt: 'sang-tao-cong-nghe-kien-tao-dat-nuoc', class: 'w-full' }"
            />
          </div>
        </div>
      </div>

      <div class="mt-10 hidden max-sm:block">
        <h2 class="tuyendung-tk-title text-primary">Tầm nhìn - Sứ mệnh</h2>
        <div class="max-w-[584px] home-4-content mt-4 mx-auto text-[#23262F]">
          Miraway tự hào sở hữu đội ngũ nhân viên trẻ trung, nhiệt huyết với kiến thức, kỹ năng đa dạng trong lĩnh vực
          công nghệ sẵn sàng đáp ứng mọi nhu cầu công nghệ của khách hàng.
        </div>
        <nuxt-picture
          loading="lazy"
          src="/images/home/gia-tri-cot-loi.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner' }"
          class="w-full"
        />
      </div>
    </section>

    <section class="mt-[80px] 2xl:container mx-auto relative">
      <div class="max-sm:hidden -mx-60 max-lg:-mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/home/the-number.png"
          :imgAttrs="{ class: 'w-full', alt: 'why-choose-us' }"
          class="w-full h-full"
        />
      </div>

      <div class="sm:hidden -mx-4">
        <nuxt-picture
          loading="lazy"
          src="/images/home/number-bg-mobie.png"
          :imgAttrs="{ class: 'w-full', alt: 'home-banner-mobile' }"
          class="w-full"
        />
      </div>

      <div class="container absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div class="max-sm:mt-[20px] max-sm:flex-col-reverse sm:items-center gap-x-28 max-lg:gap-16 max-sm:gap-5">
          <div class="mx-auto px-10 max-md:px-5">
            <h2 class="text-white home-4-title max-sm:text-[20px]">Những con số</h2>
            <div class="max-w-[676px] mt-4 text-white home-4-content max-sm:text-[16px]">
              Với mạng lưới đối tác toàn cầu và những dự án thực tế đã triển khai thành công trong và ngoài nước,
              Miraway tự tin về năng lực cung cấp và triển khai các giải pháp CNTT tại Việt Nam và các nước trong khu
              vực.
            </div>
            <WhyChooseMrw />
          </div>
        </div>
      </div>
    </section>

    <section id="why-choose-us" class="mt-[100px] 2xl:container m-auto max-sm:mt-[32px]">
      <div class="text-center">
        <div class="max-w-[584px] mx-auto home-5-title-mrw text-center text-secondary max-sm:text-[14px]">
          MIRAWAY GROUP
        </div>
        <h2
          class="home-5-title text-primary mt-2 max-sm:text-[28px] max-sm:mt-[8px] max-md:text-[28px] max-md:font-[600] max-md:leading-[34px]"
        >
          Tại sao lựa chọn chúng tôi?
        </h2>
      </div>

      <div class="mt-20 grid gap-8 grid-cols-3 max-lg:grid-cols-2 max-md:grid-cols-1 max-2xl:mt-[60px] max-md:mt-3">
        <div v-for="item in whyChooseUs" class="why-choose-us_item">
          <nuxt-picture
            v-if="item.image"
            loading="lazy"
            :src="item.image"
            :imgAttrs="{ alt: 'why choose us' + item.id }"
            class="why-choose-us_item-bg"
          />
          <div class="why-choose-us_item-content">
            <nuxt-picture
              v-if="item.icon"
              loading="lazy"
              :src="item.icon"
              :imgAttrs="{ class: 'w-[75px] h-[75px] mx-auto', alt: 'why choose us icon' + item.id }"
            />
            <div>
              <h2 :class="item.id % 2 !== 0 ? 'text-white' : 'text-primary'">
                <p class="max-md:text-[18px] font-[700] text-[#003D8A]">{{ item.title }}</p>
              </h2>
              <div class="w-1/2 h-[1px] mx-auto my-3 bg-primary" v-if="item.id % 2 === 0" />
              <p class="text-medium text-justify max-md:text-[16px] text-[#353945]">{{ item.des }}</p>
            </div>
            <div class="mark">
              {{ item.hover_content }}
              <div class="w-1/2 h-[1px] mx-auto my-3 bg-white" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="-mx-20 ecosystem-custom-bg pb-20 mt-20">
      <div class="text-right max-xl:text-center pt-20 px-20">
        <div class="home-5-title-mrw text-secondary max-xl:mx-auto max-md:text-[14px]">TÌM HIỂU NGAY</div>
        <h2 class="text-primary home-4-title mt-2 mb-[72px] max-xl:text-center max-md:text-[28px]">
          Hệ sinh thái <br />
          của Miraway Group
        </h2>
      </div>
      <Ecosystem class="-mx-20 max-md:mx-20" />

      <section id="solution" class="2xl:container mx-auto mt-20 px-20">
        <div class="max-xl:hidden -mx-60 max-lg:-mx-4"></div>
        <div class="grid grid-cols-2 gap-8 max-xl:grid-cols-1 top-[100px]">
          <div class="max-xl:text-center">
            <div class="max-w-[584px] text-[16px] text-secondary max-xl:mx-auto max-md:text-[14px]">TÌM HIỂU NGAY</div>
            <h2 class="text-primary home-4-title mt-2 text-left mb-[72px] max-xl:text-center max-md:text-[28px]">
              Giải pháp tiêu biểu của Miraway Group
            </h2>
            <nuxt-picture
              loading="lazy"
              src="/images/home/solution.png"
              :imgAttrs="{ class: 'w-full', alt: 'solution' }"
            />
          </div>
          <div class="grid grid-cols-3 max-md:grid-cols-2 text-center gap-8">
            <div v-for="item in solutions" class="solution-item">
              <nuxt-link :to="item.url" target="_blank">
                <nuxt-picture
                  loading="lazy"
                  :src="item.icon"
                  :imgAttrs="{ class: 'w-[75px] h-[75px] mx-auto', alt: 'solution icon' + item.id }"
                  class=""
                />
                <div class="solution-title">{{ item.title }}</div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section
      id="value-delivered"
      class="max-xl:mt-20 -mx-20 px-20 py-[84px] bg-[url('/images/home/value-delivered.png')] bg-cover bg-center"
    >
      <div class="text-center">
        <div class="home-5-title-mrw text-center text-white max-md:hidden">MIRAWAY GROUP</div>
        <h2 class="home-4-title text-white mt-3 max-md:text-[36px]">Giá trị mang lại</h2>
      </div>
      <div class="mt-10 max-md:hidden">
        <nuxt-picture
          loading="lazy"
          src="/images/home/value-delivered2.png"
          :imgAttrs="{ class: 'w-full', alt: 'value-delivered' }"
          class="w-full"
        />
      </div>
      <div class="hidden max-md:grid grid-cols-1 gap-3 mt-10">
        <div v-for="item in valueDelivered" :key="item.id" class="flex flex-col items-center gap-3">
          <nuxt-picture
            loading="lazy"
            :src="item.image"
            :imgAttrs="{ class: 'w-[173px] mx-auto', alt: 'value-delivered' + item.id }"
          />
          <div
            class="max-w-[332px] text-center text-white mt-4 home-giatri-content max-md:text-[16px] max-md:font-[400]"
          >
            {{ item.content }}
          </div>
          <div
            :class="`line w-0 h-[34px] border-l-2 border-dashed border-white ${
              valueDelivered.length === item.id && 'hidden'
            }`"
          ></div>
        </div>
      </div>
    </section>

    <section id="customer" class="mt-20 max-md:mt-10">
      <div class="text-center">
        <div class="home-5-title-mrw text-center text-secondary max-md:text-[14px]">TÌM HIỂU NGAY</div>
        <h2 class="home-4-title text-primary mt-3 max-md:text-[28px]">Khách hàng của Miraway</h2>
      </div>
      <div class="mt-14 max-md:mt-[40px] overflow-hidden flex">
        <div class="slide w-full shrink-0 max-md:hidden">
          <nuxt-picture
            loading="lazy"
            src="/images/customer-logo.png"
            :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
          />
        </div>

        <div class="slide slide-clone w-full shrink-0 max-md:hidden">
          <nuxt-picture
            loading="lazy"
            src="/images/customer-logo.png"
            :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
          />
        </div>

        <div class="slide w-full shrink-0 md:hidden">
          <nuxt-picture
            loading="lazy"
            src="/images/customer-logo-mobile.png"
            :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
          />
        </div>

        <div class="slide slide-clone w-full shrink-0 md:hidden">
          <nuxt-picture
            loading="lazy"
            src="/images/customer-logo-mobile.png"
            :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
          />
        </div>
      </div>
    </section>

    <LazyBlog v-if="isVisibleBlog" title="Tin tức và sự kiện" class="pb-10 2xl:container !mt-[80px] mx-auto" />
  </div>
</template>
